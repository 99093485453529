import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { brand } from 'src/styles/colors'

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      max-width: 456px!important;
      max-height: none!important;
      float: left;
    }
  }
`

export const Advantages = styled.div`
  @media ${device.tablet}{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
`

export const CardAdvantages = styled.div`
  min-height: 127px;
  padding: 32px;

  @media ${device.desktopXXL}{
    min-height: 192px;
  }

  h3 {
    color: ${brand.claryOrange};
  }
`
