import React from 'react'
import Stepper from './Stepper/_index'
import StepperHorizontal from './StepperHorizontal/_index'

// hooks
import useWidth from '../../../../../hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const WIDTH_MD = 768

// types
type heroType = {
  oneLink: string;
}

const ComoReceberPagamentos = ({ oneLink }: heroType) => {
  const dataJson = [
    '<span class="fs-16 lh-19 w-100">Abra sua conta <strong>100% gratuita e sem anuidades</strong> no Inter Empresas e conte com um Super App completo</span>',
    '<span class="fs-16 lh-19 w-100">Faça a emissão de seus boletos em poucos cliques ou utilize nossas <strong>API ou CNAB para simplificar mais ainda</strong></span>',
    '<span class="fs-16 lh-19 w-100">Programe ou envie imediatamente seus boletos e <strong>acompanhe a evolução de cada pagamento</strong></span>',
    '<span class="fs-16 lh-19 w-100">Com o pagamento efetuado, o dinheiro cai na sua conta Inter e você pode <strong>gerenciá-lo da forma que achar melhor</strong></span>',
  ]
  const windowWidth = useWidth(300)
  const isMobileSize = windowWidth < WIDTH_MD
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-citrina fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-md-center mb-5'>Como receber pagamentos no Inter Empresas?</h2>
            {isMobileSize ? <Stepper data={dataJson} /> : <StepperHorizontal data={dataJson} />}

            <div className='d-flex justify-content-md-center'>
              <a
                href={oneLink}
                target='_blank'
                rel='noreferrer'
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 py-2'
                title='Abrir conta PJ'
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Sua gestão de cobranças fica muito mais completa com o Inter Empresas',
                  element_action: 'click button',
                  element_name: 'Abrir conta PJ',
                })
                }}
              >
                Abrir conta PJ
              </a>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default ComoReceberPagamentos
