import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import { spacing16, spacing24 } from 'src/styles/spacings'

export const ImageContent = styled.div`
  .img-box {
    max-width: 240px;
    display: flex;
    align-items: center;
    margin-bottom: ${spacing24};
    @media ${device.tablet} {
      max-width: 312px;
      margin-bottom: 0;
    }

    @media ${device.desktopLG} {
      max-width: 376px;
    }

    @media ${device.desktopXXL} {
      max-width: 456px;
    }
  }
`

export const TextContent = styled.section`

  @media ${device.tablet} {
    min-height: 406px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media ${device.desktopLG} {
    min-height: 442px;
  }

  & .carousel__title {
    font-family: "Citrina", Helvetica, sans-serif;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    color: #fff;
    margin-bottom: ${spacing24};

    @media ${device.desktopLG} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .carousel__description {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin-bottom: ${spacing16};
    @media ${device.desktopLG} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .react-multi-carousel-track {
    padding: 0;
    padding-bottom: calc(${spacing24} * 2);

    .react-multi-carousel-item {
      height: 0;
    }

    .react-multi-carousel-item.react-multi-carousel-item--active {
      display: inline-block;
      height: fit-content;
    }
  }

  .react-multiple-carousel__arrow {
    svg {
      fill: ${white};
    }
  }
`
