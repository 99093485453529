import React from 'react'
import { StepContainer, Step, Circle } from './styles'

interface IStepperProps {
  data: string[];
}

const Stepper = ({ data }: IStepperProps) => {
  return (
    <StepContainer>
      {data?.map((text: string, i: number) => (
        <Step key={i}>
          <div className='cardContent font-sora'>
            <Circle>{i + 1}</Circle>
          </div>
          <div>
            <div className='caption' dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </Step>
      ))}
    </StepContainer>
  )
}

export default Stepper
