import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import useUtms from 'src/hooks/useUtms'

import Hero from './sections/hero/_index'
import GarantaBoletos from './sections/garanta-boletos/_index'
import VantagensDaGestao from './sections/vantagens-da-gestao/_index'
import ComoReceberPagamentos from './sections/como-receber-pagamentos/_index'
import AcompanheDePerto from './sections/acompanhe-de-perto/_index'
import CobrarClientes from './sections/cobrar-clientes/_index'

import pageContextJSON from './pageContext.json'
import { Content } from './style'

const GestaoDeCobranca = () => {
  const oneLink = 'https://conta-digital-pj.inter.co/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069'
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Content>
      <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContextJSON}>
        <Hero oneLink={oneLink} />
        <GarantaBoletos />
        <CobrarClientes />
        <VantagensDaGestao oneLink={oneLink} />
        <AcompanheDePerto oneLink={oneLink} />
        <ComoReceberPagamentos oneLink={oneLink} />
      </Layout>
    </Content>
  )
}

export default GestaoDeCobranca
