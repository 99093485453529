import React from 'react'

import * as S from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import CobrarClientesJSON from '../../assets/data/cobrar-clientes.json'
import SectionComponent from 'src/components/SectionComponent'
import { orange } from 'src/styles/newColors'
import Picture from 'src/components/Picture'
import IconsSwitch from 'src/components/IconsSwitch/_index'

interface ICarouselData {
  bulletPoints: IBulletPointsData[];
}

interface IBulletPointsData {
  icon: string;
  text: string;
}

const CobrarClientes = () => {
  return (
    <SectionComponent id='cobrar-clientes' minHeight={{ sm: '620px', md: '463px', lg: '504px', xl: '648px' }} bgColor={orange.dark}>
      <S.ImageContent className='col-12 col-md-7 col-xl-6'>
        <Picture
          minHeight={{ sm: '240px', md: '312px', lg: '376px', xl: '456px' }}
          width='100%'
          imgClassName='mx-auto img-box'
          alt='Homem, usando seu celular em notebook, enquanto trabalha em sua casa.'
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/gestao-dobra3/image.webp'
        />
      </S.ImageContent>
      <S.TextContent className='col-12 col-md-5 col-xl-6'>
        <h2 className='carousel__title'>
          Cobrar clientes fica mais simples com a ferramenta certa
        </h2>
        <p className='carousel__description'>Escolha o tipo de cobrança ideal para cada situação e receba via código de barras ou QRCode no mesmo boleto.</p>
        <div>
          <DefaultCarousel
            sm={{ items: 1 }}
            md={{ items: 1 }}
            lg={{ items: 1 }}
            xl={{ items: 1 }}
            customDotColor='#fff'
          >
            {CobrarClientesJSON.map((item: ICarouselData, index: number) => (
              <div key={'gestao-carrousel-' + index}>
                {item.bulletPoints.map((item: IBulletPointsData) => (
                  <div className='d-flex mb-2 align-items-center' key={item.text}>
                    <div className='icon-circle'>
                      <IconsSwitch lib='interco' icon={item.icon} size='SM' color='#fff' />
                    </div>
                    <div className='ml-3'>
                      <p className='fs-14 lh-16 fs-lg-16 lh-lg-20 text-white mb-0'>{item.text}</p>
                    </div>
                  </div>
                    ))}
              </div>
                  ))}
          </DefaultCarousel>
        </div>
      </S.TextContent>
    </SectionComponent>
  )
}

export default CobrarClientes
