import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'
import { spacing16, spacing24, spacing32, spacing8 } from 'src/styles/spacings'

export const Section = styled.section`
  background-color: ${primary[500]};
  min-height: 420px;

  .title {
    font-family: 'Citrina', sans-serif;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: #fff;
    margin-bottom: ${spacing16};
    @media ${device.desktopLG} {
      font-size: 32px;
      line-height: 32px;
    }

    @media ${device.desktopXXL} {
      font-size: 56px;
      line-height: 56px;
    }
  }

  .hero-btn:first-of-type {
    margin-bottom: ${spacing24};
    background-color: #fff;
  }

  .hero-btn:last-of-type {
    color: #fff;
  }

  .description {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: ${spacing24};

    @media ${device.tablet} {
      margin-bottom: calc(${spacing32} + ${spacing8});
    }

    @media ${device.desktopLG} {
      font-size: 18px;
      line-height: 20px;
    }

  }

  @media ${device.tablet} {
    min-height: 512px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/gestao-hero/image.webp') no-repeat, ${primary[500]};
    background-position: 265%;
    background-size: auto 100%;
  }

  @media ${device.desktopLG} {
    min-height: 586px;
    background-position: 170%;
  }

  @media ${device.desktopXL} {
    min-height: 614px;
    background-position: right;
  }

  @media ${device.desktopXXXL} {
    min-height: 614px;
    background-size: contain;
    background-position: right;
  }
`
