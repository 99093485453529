import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'
import { orange, sand } from 'src/styles/newColors'

export const TableSection = styled.section`
  h2 {
    color: ${green.extraDark};
  }
`

export const SolutionsTable = styled.div`
  position: relative;

  .lineColor {
    :nth-child(odd) {
      background-color: ${sand};
    }
  }

  .tabShadow {
    position: absolute;
    right: 0px;
    background: transparent;
    width: 78px;
    height: 648px;
    border: 2px solid ${orange.light};
    box-shadow: 0px 0px 20px 0px ${orange.light};

    @media ${device.tablet} {
      width: 134px;
      height: 690px;
    }
    @media ${device.desktopLG} {
      width: 159px;
    }
    @media ${device.desktopXL} {
      width: 189px
    }
    @media ${device.desktopXXXL} {
      width: 170px;
    }
  }
`

export const TableCell = styled.div`
  height: 81px;

  @media ${device.tablet} {
    height: 86px;
  }

  div {
    width: 81px;

    &:first-child {
      width: 145px;
    }

    @media ${device.tablet} {
      width: 134px;

      &:first-child {
        width: 366px;
      }
    }

    @media ${device.desktopLG} {
      width: 157px;

      &:first-child {
        width: 474px;
      }
    }

    @media ${device.desktopXL} {
      width: 187px;

      &:first-child {
        width: 612px;
      }
    }

    @media ${device.desktopXXXL} {
      width: 171px;
    }

    img {
      width: 24px;

      @media ${device.tablet} {
        width: 38px;
      }
    }
  }

`
