import React, { useEffect } from 'react'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Styles
import { Section } from './style'
import { Button } from '@interco/inter-ui/components/Button'
import { initTheme, Theme } from '@interco/inter-ui'
// types
type heroType = {
  oneLink: string;
}

const TEXT_CONTENT = {
  title: 'Gestão de cobranças muito mais completa com Inter Empresas.',
  description: 'Simplifique a rotina do seu negócio com emissão de boletos gratuitos no Super App',
  openAccountBtn: 'Abrir conta PJ',
  signUpBtn: 'Contratar',
}

const Hero = ({ oneLink }: heroType) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  useEffect(() => {
    initTheme(Theme.PF)
  })

  return (
    <Section className='d-flex align-items-center position-relative py-5' role='img' aria-label='Empreendedora em sua lanchonete, sorrindo enquanto utiliza seu smartphone.'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 px-0'>
            <h1 className='title'>
              {TEXT_CONTENT.title}
            </h1>
            <p className='description'>
              {TEXT_CONTENT.description}
            </p>

            <Button
              as='a'
              href={oneLink}
              fullWidth
              target='_blank'
              rel='noreferrer'
              variant='secondary'
              className='hero-btn'
              title={TEXT_CONTENT.openAccountBtn}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Sua gestão de cobranças fica muito mais completa com o Inter Empresas',
                  element_action: 'click button',
                  element_name: 'Abrir conta PJ',
                })
              }}
            >
              {TEXT_CONTENT.openAccountBtn}
            </Button>

            <Button
              as='a'
              fullWidth
              href='https://intergo.app/83634c54'
              target='_blank'
              rel='noreferrer'
              variant='secondary'
              className='hero-btn'
              title={TEXT_CONTENT.signUpBtn}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Global Account Inter: a sua conta internacional',
                  element_action: 'click button',
                  element_name: TEXT_CONTENT.signUpBtn,
                  redirect_url: oneLink,
                })
              }}
            >
              {TEXT_CONTENT.signUpBtn}
            </Button>

          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
